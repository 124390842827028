<template>
  <div class="_wrap">
    <div class="_banner">
      <!-- <img class="_bimg" src="@/assets/image/exhbit-img/zhanlan_banner.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
      <img
        class="_bimg"
        :src="$IMG_URL + $store.state.webData.top_image.exhibition_list_image"
        alt="八路军驻洛办事处纪念馆-官方网站"
      />

      <!-- <img class="_zlImg" src="@/assets/image/exhbit-img/zhanlan_bg_text.png" alt="八路军驻洛办事处纪念馆-官方网站"> -->
    </div>

    <div class="_first" id="jbcl">
      <div class="_auto">
        <div class="_cont">
          <h4>抗战堡垒•红色枢纽——八路军驻洛办事处史实展览</h4>
          <div class="_list">

            <div class="_ul">
              <div
              class="_li"
              v-for="(item, index) in ztList"
              :key="index"
              @click="goDetail(item)"
            >
              <div class="_tit">
                <p>
                  <span>{{ item.exhibition_name }}</span>
                </p>
                <div class="_nr">
                  <div class="_p">{{ item.title }}</div>
                  <div class="_pro">{{ item.introduction }}</div>
                </div>
              </div>

              <img
                class="_img"
                :src="$IMG_URL + item.cover"
                alt="八路军驻洛办事处纪念馆-官方网站"
              />
            </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="_two" id="lszl">
      <h4 class="t_h4">临时展览</h4>

      <div class="_list" v-if="ls_list.length > 0">
        <div class="_left">
          <img
            :src="$IMG_URL + ls_list[0].cover"
            alt="八路军驻洛办事处纪念馆-官方网站"
            @click="go3D(ls_list[0])"
          />
        </div>
        <div class="_right">
          <div class="_r_nr">
            <h4>{{ ls_list[0].tempexhibition_name }}</h4>

            <div class="_div">
              <p>
                {{ ls_list[0].introduction }}
              </p>

              <!-- <button>全景漫游</button> -->
              <div
                class="_bbtn"
                v-if="ls_list[0].vr != '' && ls_list[0].vr != null"
                @click="go3D(ls_list[0])"
              >
                <span>进入全景漫游</span>
                <img src="../../assets/image/exhbit-img/jt.png" alt="" />
              </div>
            </div>

            <div class="_page">
              <pagination
                v-show="total > 0"
                :total="total"
                :page.sync="queryParams.page"
                :limit.sync="queryParams.page_num"
                @pagination="getList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="_three" id="wwjl">
      <div class="_auto">
        <div class="_cont">
          <h4 class="_h4">文物交流</h4>
          <div class="_list">
            <div class="_li" v-for="(item, index) in wwData" :key="index">
              <div class="_top">
                <div class="_t_nr">
                  <h4>{{ item.collection_exchange_name }}</h4>
                  <p class="_p">{{ item.introduction }}</p>

                  <p class="_time _impact">时间：{{ item.release_date }}</p>
                  <p class="_dd">地点：{{ item.address }}</p>

                  <div class="_pz">
                    <p>票种：{{ item.ticket_type }}</p>
                    <span @click="getDetail2(item)">查看详情</span>
                  </div>
                </div>
              </div>

              <div class="_bottom">
                <img src="../../assets/image/exhbit-img/zhanlan_picture_1.png" alt="八路军驻洛办事处纪念馆-官方网站">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { exhibitionList, tempExhibit, collectionExchange } from "@/api/getData";
export default {
  data() {
    return {
      wbTitle: "八路军驻洛办事处纪念馆-展览",
      keywords: "八路军驻洛办事处纪念馆,洛八办",
      desc: "八路军驻洛办事处纪念馆",
      title: "",
      obj: {},
      nrFlag: false,
      ztList: [
        {
          title: "第一展厅",
          h4: "形式逆转,落班撤离",
          dis: "1941年12月，蒋介石以“中条山战役”失利为名撤销了卫立煌的第一战区司令长官之职，改由反共坚决的蒋...",
          img: require("../../assets/image/exhbit-img/zhanlan_picture_3.png"),
        },
        {
          title: "第二展厅",
          h4: "形式逆转,落班撤离",
          dis: "1941年12月，蒋介石以“中条山战役”失利为名撤销了卫立煌的第一战区司令长官之职，改由反共坚决的蒋...",
          img: require("../../assets/image/exhbit-img/zhanlan_picture_3.png"),
        },
        {
          title: "第三展厅",
          h4: "形式逆转,落班撤离",
          dis: "1941年12月，蒋介石以“中条山战役”失利为名撤销了卫立煌的第一战区司令长官之职，改由反共坚决的蒋...",
          img: require("../../assets/image/exhbit-img/zhanlan_picture_3.png"),
        },
        {
          title: "第四展厅",
          h4: "形式逆转,落班撤离",
          dis: "1941年12月，蒋介石以“中条山战役”失利为名撤销了卫立煌的第一战区司令长官之职，改由反共坚决的蒋...",
          img: require("../../assets/image/exhbit-img/zhanlan_picture_3.png"),
        },
        {
          title: "第五展厅",
          h4: "形式逆转,落班撤离",
          dis: "1941年12月，蒋介石以“中条山战役”失利为名撤销了卫立煌的第一战区司令长官之职，改由反共坚决的蒋...",
          img: require("../../assets/image/exhbit-img/zhanlan_picture_3.png"),
        },
        {
          title: "第六展厅",
          h4: "形式逆转,落班撤离",
          dis: "1941年12月，蒋介石以“中条山战役”失利为名撤销了卫立煌的第一战区司令长官之职，改由反共坚决的蒋...",
          img: require("../../assets/image/exhbit-img/zhanlan_picture_3.png"),
        },
      ],
      ls_list: [],
      wwData: [],
      total: 100,
      queryParams: {
        page: 1,
        page_num: 1,
      },
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: this.wbTitle,
      // meta: [
      //   { name: "keywords", content: this.keywords },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let title = to.query.tit;
      this.title = title;
      for (let i in this.obj) {
        console.log(i);
        if (this.title == i) {
          this.$scrollToSection(this.obj[i]);
        }
      }
    },
  },

  async mounted() {
    // 基本陈列
    await this.getDataCL();
    // 临时展览
    await this.getList();

    // 文物交流
    await this.getWwData();

    this.jumpId();
  },
  methods: {
    jumpId() {
      const title = this.$route.query.tit;
      console.log(title);
      const obj = {
        基本陈列: "jbcl",
        临时展览: "lszl",
        文物交流: "wwjl",
      };
      this.obj = obj;

      if (title) {
        for (let i in obj) {
          // console.log(i)
          if (title == i) {
            this.$scrollToSection(obj[i]);
          }
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动
        });
      }
    },
    // 基本陈列
    async getDataCL() {
      await exhibitionList().then((res) => {
        console.log(res);
        this.ztList = res.data.exhibition;
      });
    },
    // 临时展览
    async getList() {
      await tempExhibit(this.queryParams).then((res) => {
        console.log(res);
        this.ls_list = res.data.data;
        this.total = res.data.total;
        console.log(this.total);
      });
    },
    // 文物交流
    async getWwData() {
      await collectionExchange().then((res) => {
        console.log(res);
        this.wwData = res.data.data;
      });
    },

    go3D(item) {
      if (item.vr != "" && item.vr != null) {
        window.open(item.vr);
      }
    },

    // 基本陈列
    goDetail(item, tit) {
      // let url = location.origin
      // console.log(url)
      // window.open(`${url}/exhibitDetail?id=${item.id}`)
      window.open(item.digital_exhibition_url);
    },
    // 临时展览

    goDetail1() {
      let url = location.origin;
      console.log(url);
      window.open(`${url}/tempExhibitDetail?id=${this.ls_list[0].id}`);
    },
    // 文物交流
    getDetail2(item) {
      let url = location.origin;
      console.log(url);
      window.open(`${url}/collectionExchangeDetail?id=${item.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

@mixin ba_cont {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #831313;
  transform: rotate(45deg);
}

@mixin bgI($url) {
  background-image: url($url);
  // background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

._wrap {
  width: 100%;
  overflow: hidden;
  background-color: #fdfaf6;
  // background-color: red;
}

._banner {
  width: 100%;
  height: 900px;
  position: relative;

  ._bimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ._zlImg {
    position: absolute;
    top: 252px;
    left: 50%;
    transform: translateX(-152px);
  }
}

._auto {
  width: 75%;
  margin: 0 auto;
}

._first {
  width: 100%;
  margin-top: -440px;
  position: relative;
  z-index: 99;

  ._cont {
    h4 {
      font-size: 36px;
      color: white;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }

    h4::before {
      @include ba_cont;
      background-color: #fff;
      margin-right: 20px;
    }

    ._list {


      ._ul{
        display: flex;
        align-items: center;
      }

      ._li {
        cursor: pointer;
        width: 16.6666%;
        height: 487px;
        @include bgI("../../assets/image/exhbit-img/zhanlan_bg_chenlie_1.png");

        ._tit {
          height: 50%;

          text-align: center;
          color: white;
          overflow: hidden;
          transition: all 0.2s ease;
          position: relative;

          ._nr {
            height: 0;
            overflow: hidden;
            transition: all 0.2s ease;
            // padding: 0 26px 48px 18px;
            padding: 0 18px;

            ._p {
              font-size: 18px;
              margin-top: 32px;
              margin-bottom: 12px;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            ._pro {
              width: 100%;
              // padding: 0 26px 48px 12px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              /* 设置最大显示行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              text-align: left;
              letter-spacing: 1px;
            }
          }

          p {
            margin-top: 82px;

            span {
              font-size: 30px;
              overflow: hidden;
            }

            span::before {
              content: "";
              display: inline-block;
              width: 172px;
              height: 72px;
              border: 1px solid #63423d;
              // position: absolute;
              // left: -30px;
              // top: -17px;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: 0 auto;
              margin-top: 66px;

              // display: none;
            }

            span::after {
              content: "";
              display: inline-block;
              width: 160px;
              height: 60px;
              border: 1px solid #fdfdfd;
              // position: absolute;
              // left: -24px;
              // top: -11px;
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              margin: 0 auto;
              margin-top: 72px;
              // display: none;
            }
          }
        }

        ._tit:hover {
          height: 350px;
        }

        ._tit:hover ._nr {
          // display: block;
          height: 200px;
        }

        ._tit:hover > p span::before {
          display: block;
        }

        ._tit:hover > p span::after {
          display: block;
        }

        ._tit:hover + ._img {
          height: 137px;
        }

        ._img {
          width: 100%;
          height: 50%;
          transition: all 0.2s ease;
          object-fit: cover;
        }
      }

      ._li:nth-child(2) {
        @include bgI("../../assets/image/exhbit-img/zhanlan_bg_chenlie_2.png");
      }

      ._li:nth-child(3) {
        @include bgI("../../assets/image/exhbit-img/zhanlan_bg_chenlie_3.png");
      }

      ._li:nth-child(4) {
        @include bgI("../../assets/image/exhbit-img/zhanlan_bg_chenlie_4.png");
      }

      ._li:nth-child(5) {
        @include bgI("../../assets/image/exhbit-img/zhanlan_bg_chenlie_5.png");
      }

      ._li:nth-child(6) {
        @include bgI("../../assets/image/exhbit-img/zhanlan_bg_chenlie_6.png");
      }
    }
  }
}

._two {
  margin-top: 80px;
  margin-bottom: 100px;

  .t_h4 {
    width: 75%;
    margin: 0 auto;
    font-size: 36px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    color: #333;
  }

  .t_h4::before {
    @include ba_cont;
    margin-right: 20px;
  }

  ._list {
    display: flex;

    ._left {
      width: 50%;
      height: 500px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        transition: all 0.8s;
        object-fit: cover;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    // ._right:hover ._r_nr h4{
    //   color: $hover-color;
    // }
    ._right {
      width: 50%;
      @include bgI("../../assets/image/home-img/bg_zhanlan_linshizhanlan.png");

      ._r_nr {
        padding: 72px 58px 0 58px;

        h4 {
          width: 100%;
          font-size: 24px;
          color: white;
          margin-bottom: 38px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        ._div {
          height: 220px;

          ._bbtn {
            color: white;
            // border: 1px solid white;
            background: #B92D2D;
            width: 139px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            margin-top: 20px;

            img {
              margin-left: 5px;
              vertical-align: middle;
            }
          }
        }

        p {
          color: white;
          font-size: 18px;
          letter-spacing: 1px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }

        ._page {
          // margin-top: 103px;
          text-align: center;
        }
      }
    }
  }
}

._three {
  margin-top: 80px;
  margin-bottom: 100px;

  ._cont {
    ._h4 {
      font-size: 36px;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      color: #333;
    }

    ._h4::before {
      @include ba_cont;
      margin-right: 20px;
    }

    ._list {
      display: flex;

      ._li {
        cursor: pointer;

        color: white;

        ._top:hover ._t_nr h4 {
          color: $hover-color;
        }

        ._top {
          background-image: url("../../assets/image/exhbit-img/zhanlan_bg_jiaoliu_1.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          height: 337px;

          ._t_nr {
            padding: 37px 51px 25px 26px;

            h4 {
              font-size: 20px;
              margin-bottom: 20px;
            }

            ._p {
              margin-bottom: 56px;
              letter-spacing: 1px;
              width: 100%;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              /* 设置最大显示行数 */
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }

            ._dd {
              margin: 10px 0;
            }

            ._pz {
              display: flex;
              justify-content: space-between;

              // align-items: end;
              span {
                padding: 7px 16px;
                border: 1px solid #fff;
                cursor: pointer;
                text-align: center;
                transform: translateY(-15px);
              }
            }
          }
        }

        ._bottom {
          overflow: hidden;
          width: 100%;
          height: 337px;

          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            cursor: pointer;
          }

          img:hover {
            transform: scale(1.1);
          }
        }
      }

      ._li:nth-child(2) {
        ._top {
          transform: translateY(100%);
          background-image: url("../../assets/image/exhbit-img/zhanlan_bg_jiaoliu_2.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        ._bottom {
          transform: translateY(-100%);
        }
      }

      ._li:nth-child(3) {
        ._top {
          background-image: url("../../assets/image/exhbit-img/zhanlan_bg_jiaoliu_3.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

@keyframes ztAnimate {
  from {
    height: 247px;
  }

  to {
    height: 350px;
  }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #b92d2d;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: white;
}

::v-deep ._page .el-pager li {
  border-radius: 50% !important;
  background-color: transparent !important;
  color: white;
  border-width: 2px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
}

::v-deep ._page .el-pagination .btn-prev {
  border-radius: 50% !important;
  background-color: transparent !important;
  color: white;
  border-width: 2px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 20px;
}

::v-deep ._page .el-pagination .btn-next {
  border-radius: 50% !important;
  background-color: transparent !important;
  color: white;
  border-width: 2px;
  width: 48px;
  height: 48px;
  line-height: 48px;
}

::v-deep ._page .el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 20px;
}

::v-deep ._page .el-icon-arrow-left {
  font-size: 20px !important;
}

@include respondTo("phone") {
  ._auto {
    padding: 0 8px;
  }
  ._banner {
    height: 300px;
  }
  ._first {
    margin-top: -130px;
    ._auto {
      padding: 0;
    }
    ._cont {
      h4 {
        font-size: 22px;
        margin-bottom: 20px;
        padding: 0 8px;
      }
      h4::before{
        flex: none;
      }
      ._list {
        overflow: hidden;
        width: 100%;
        ._ul{
          overflow-x: auto;
          overflow-y: hidden;
          width: auto;

        }
        ._li {
          width: 230px;
          flex: none;
          height: 400px;
          margin-right: 10px;
          background-size: 100% 100%;
          ._img {
          }
        }
        ._li:nth-child(5){
          background-size: inherit;
        }
      }
    }
  }

  ._two{
    margin-top: 20px;
    margin-bottom: 0;
    .t_h4{
      padding: 0 8px;
      width: 100%;
      font-size: 22px;
      margin-bottom: 20px;
    }
    ._list{
      display: block;
      ._left{
        width: 100%;
        height: 300px;
        img{
        }
      }
      ._right{
        width: 100%;
        ._r_nr{
          padding: 20px;
          ._div{
            height: 200px;
          }
          h4{
            font-size: 18px;
            margin-bottom: 20px;
          }
          p{
            font-size: 16px;
            height: 130px;
            overflow-y: scroll;
            
          }
        }
      }
    }
  }

  ::v-deep ._page .el-pagination .btn-prev{
    width: 34px;
    height: 34px;
    line-height: 34px;
  }

  ::v-deep ._page .el-pagination .btn-next{
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
  ::v-deep ._page .el-pager li{
    width: 34px;
    height: 34px;
    line-height: 32px;
    font-size: 16px;
  }
}
</style>